<ng-container *ngIf="!quotationSubmitted; else instantQuote">
	<div class="form-container-bg">
		<div>
			<div>
				<div class="forms">
					<mat-card>
						<mat-stepper #stepper linear class="pt-md">
							<mat-step [stepControl]="vehicleFormGroup">
								<form [formGroup]="vehicleFormGroup" #vehicleForm="ngForm">
									<ng-template matStepLabel>
										<span>{{ "dashboard.stepOne" | translate }}</span>
									</ng-template>

									<mat-form-field appearance="outline">
										<mat-label>{{
											"common.typeOfInsurance" | translate
										}}</mat-label>
										<mat-select
											disableOptionCentering
											formControlName="InsuranceType"
											required
										>
											<mat-option
												*ngFor="let item of typeOfInsuranceList"
												[value]="item.id"
											>
												{{ item.name | translate }}
											</mat-option>
										</mat-select>
										<mat-error
											>{{ "common.typeOfInsurance" | translate }}
											{{
												vehicleFormGroup.controls.InsuranceType.errors
													| inputError
													| translate
											}}</mat-error
										>
									</mat-form-field>

									<mat-icon
										class="tooltip-icon"
										[matMenuTriggerFor]="typeTooltip"
										#typeTrigger="matMenuTrigger"
										(mouseenter)="typeTrigger.openMenu()"
									>
										info
									</mat-icon>

									<mat-form-field appearance="outline" class="upperc-input">
										<mat-label>{{ "common.carPlateNo" | translate }}</mat-label>
										<input
											matInput
											required
											formControlName="VehicleNo"
											mask="A{255}"
											[validation]="false"
											autocomplete="off"
										/>
										<!-- <mat-error>{{ 'common.carPlateNo' | translate }} {{ vehicleFormGroup.controls.carPlate.errors |
                    inputError | translate }}</mat-error> -->
									</mat-form-field>

									<mat-form-field appearance="outline">
										<mat-label>{{
											"common.residentialPostcode" | translate
										}}</mat-label>
										<input
											matInput
											required
											formControlName="PostCode"
											mask="00000"
											autocomplete="postal-code"
										/>
										<mat-error style="display: block !important" [hidden]="!vehicleFormGroup.controls.PostCode?.errors?.customError">
										  {{ "error.invalidPostcode" | translate }}
										</mat-error>
									</mat-form-field>
									<div style="display: block">
										<span class="label">
											<mat-label
												>{{ "common.businessType" | translate }}:</mat-label
											>
										</span>
										<section class="box-selection">
											<mat-radio-group
												class="b2c-radio"
												aria-label="Select an option"
												formControlName="BusinessType"
											>
												<mat-radio-button
													[value]="businessTypeOption.transferOwnership"
													selected
													>{{ "common.transferOwnership" | translate }}
												</mat-radio-button>
												<mat-radio-button [value]="businessTypeOption.renewal"
													>{{ "common.renewal" | translate }}
												</mat-radio-button>
											</mat-radio-group>
										</section>
										
										<section class="box-selection">
											<mat-radio-group
												class="b2c-radio"
												aria-label="Select an option"
												formControlName="TypeOfSum"
												*ngIf="isRenewalOptionChosen()"
											>
												<mat-radio-button
													[value]="typeOfSumOption.marketValue"
													selected
													>{{ "common.MV" | translate }}

													<mat-icon
														class="tooltip-icon"
														[matMenuTriggerFor]="mvTooltip"
														#mvTrigger="matMenuTrigger"
														(mouseenter)="mvTrigger.openMenu()"
														style="margin-top: 0px;"
														>
														info
													</mat-icon>

													<mat-menu
														#mvTooltip="matMenu"
														[overlapTrigger]="false"
														class="tooltip-popover"
														>
														<span (mouseleave)="mvTrigger.closeMenu()">
															<div class="title" style="color: #49b0ff;">
																{{ "common.MV" | translate }}:
															</div>
																{{ "common.marketValDesc" | translate }}	
														</span>		
													</mat-menu>

												</mat-radio-button>
												<mat-radio-button [value]="typeOfSumOption.agreedValue"
													>{{ "common.AV" | translate }}

													<mat-icon
														class="tooltip-icon"
														[matMenuTriggerFor]="avTooltip"
														#avTrigger="matMenuTrigger"
														(mouseenter)="avTrigger.openMenu()"
														style="margin-top: 0px;"
														>
														info
													</mat-icon>

													<mat-menu
														#avTooltip="matMenu"
														[overlapTrigger]="false"
														class="tooltip-popover"
														>
														<span (mouseleave)="avTrigger.closeMenu()">
															<div class="title" style="color: #49b0ff;">
																{{ "common.AV" | translate }}:
															</div>
																{{ "common.agreedValDesc" | translate }}	
														</span>		
													</mat-menu>
												</mat-radio-button>
											</mat-radio-group>
										</section>

									</div>

									<button
										mat-flat-button
										matStepperNext
										[disabled]="!vehicleFormGroup.valid"
									>
										{{ "common.proceed" | translate }}
									</button>
								</form>
							</mat-step>
							<!-- Step 2 -->
							<mat-step [stepControl]="custFormGroup">
								<form
									[formGroup]="custFormGroup"
									#custForm="ngForm"
									(ngSubmit)="submitFormData()"
								>
									<div class="mdTitle-f mb-sm">Buyer’s details</div>
									<ng-template matStepLabel>
										<span>{{ "dashboard.stepTwo" | translate }}</span>
									</ng-template>
									<mat-form-field appearance="outline">
										<mat-label>{{ "common.idType" | translate }} </mat-label>
										<mat-select
											disableOptionCentering
											[ngModelOptions]="{ standalone: true }"
											[(ngModel)]="idType"
											(selectionChange)="idTypeChange($event)"
										>
											<mat-option
												*ngFor="let item of idTypeList"
												[value]="item.id"
											>
												{{ item.name | translate }}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<!--detais for foreigner only  -->
									<div *ngIf="!isMalaysian">
										<mat-form-field appearance="outline">
											<mat-label>{{
												"common.nationality" | translate
											}}</mat-label>
											<mat-select formControlName="Nationality">
												<mat-option>
													<ngx-mat-select-search
														[placeholderLabel]="
															'common.nationalityPH' | translate
														"
														[ngModelOptions]="{ standalone: true }"
														[searching]="!countryList.length"
														noEntriesFoundLabel="{{
															'common.nationalityXrecord' | translate
														}}"
														(ngModelChange)="filterCountry($event)"
														ngModel
														ngDefaultControl
													>
													</ngx-mat-select-search>
												</mat-option>
												<mat-option
													*ngFor="let item of filterCountryList"
													[value]="item"
												>
													{{ item }}</mat-option
												>
											</mat-select>
											<mat-error
												>{{ "common.nationality" | translate }}
												{{
													custFormGroup.controls.Nationality.errors
														| inputError
														| translate
												}}</mat-error
											>
										</mat-form-field>
									</div>

									<mat-form-field appearance="outline">
										<mat-label>
											{{ getIdentityNoLabel() | translate }}
										</mat-label>
										<input
											matInput
											required
											formControlName="IdentityNo"
											[mask]="getIdentityNoMasking()"
											autocomplete="off"
											[maxLength]="isMalaysian ? 12 : 99"
										/>
										<mat-error
											>{{ getIdentityNoLabel() | translate }}
											{{
												custFormGroup.controls.IdentityNo.errors
													| inputError
													| translate
											}}</mat-error
										>
									</mat-form-field>

									<div *ngIf="!isCompany">
										<!-- details for malaysian only -->
										<mat-form-field appearance="outline" *ngIf="isMalaysian">
											<mat-label>{{
												"common.policeOrArmyIcNo" | translate
											}}</mat-label>
											<input
												matInput
												autocomplete="off"
												formControlName="PolicIdentityNo"
											/>
										</mat-form-field>

										<mat-form-field appearance="outline">
											<mat-label>{{ getNameLabel() | translate }}</mat-label>
											<input
												required
												matInput
												autocomplete="name"
												formControlName="Name"
											/>
										</mat-form-field>

										<mat-form-field appearance="outline">
											<mat-label>{{ "common.dob" | translate }}</mat-label>
											<input
												matInput
												[matDatepicker]="picker"
												formControlName="Dob"
												readonly
												(click)="picker.open()"
												[max]="maxDob"
											/>
											<mat-datepicker-toggle
												matSuffix
												[for]="picker"
											></mat-datepicker-toggle>
											<mat-datepicker #picker></mat-datepicker>
											<mat-error
												>{{ "common.dob" | translate }}
												{{
													custFormGroup.controls.Dob.errors
														| inputError
														| translate
												}}</mat-error
											>
										</mat-form-field>

										<mat-form-field appearance="outline" *ngIf="isMalaysian">
											<mat-label>{{ "common.gender" | translate }} </mat-label>
											<mat-select formControlName="Gender">
												<mat-option [value]="genderOption.male">{{
													"common.M" | translate
												}}</mat-option>
												<mat-option [value]="genderOption.female">{{
													"common.F" | translate
												}}</mat-option>
											</mat-select>
											<mat-error
												>{{ "common.gender" | translate }}
												{{
													custFormGroup.controls.Gender.errors
														| inputError
														| translate
												}}</mat-error
											>
										</mat-form-field>

										<section class="box-selection" *ngIf="!isMalaysian">
											<mat-label>{{ "common.gender" | translate }}</mat-label>
											<mat-radio-group
												class="b2c-radio"
												aria-label="Select an option"
												formControlName="Gender"
											>
												<mat-radio-button [value]="genderOption.male"
													>{{ "common.M" | translate }}
												</mat-radio-button>
												<mat-radio-button [value]="genderOption.female"
													>{{ "common.F" | translate }}
												</mat-radio-button>
											</mat-radio-group>
											<mat-error
												>{{ "common.gender" | translate }}
												{{
													custFormGroup.controls.Gender.errors
														| inputError
														| translate
												}}</mat-error
											>
										</section>

										<section class="box-selection">
											<mat-label>{{
												"common.maritalStatus" | translate
											}}</mat-label>
											<mat-radio-group
												class="b2c-radio"
												aria-label="Select an option"
												formControlName="MaritalStatus"
											>
												<mat-radio-button [value]="maritalStatusOption.single"
													>{{ "common.Single" | translate }}
												</mat-radio-button>
												<mat-radio-button [value]="maritalStatusOption.married"
													>{{ "common.Married" | translate }}
												</mat-radio-button>
											</mat-radio-group>
										</section>
									</div>

									<div class="mdTitle-f mb-sm">Agent's details</div>

									<mat-form-field appearance="outline">
										<mat-label>{{ "common.mobileNo" | translate }}</mat-label>
										<ngx-mat-intl-tel-input
											[enableSearch]="true"
											[preferredCountries]="['my']"
											formControlName="AgentMobileNo"
										>
										</ngx-mat-intl-tel-input>
										<mat-error
											>{{ "common.mobileNo" | translate }}
											{{
												custFormGroup.controls.AgentMobileNo.errors
													| inputError
													| translate
											}}</mat-error
										>
									</mat-form-field>

									<mat-form-field appearance="outline">
										<mat-label>{{ "common.email" | translate }}</mat-label>
										<input
											matInput
											formControlName="AgentEmail"
											autocomplete="email"
										/>
										<mat-error
											style="display: block !important"
											[hidden]="
												!custFormGroup.controls.AgentEmail?.errors?.AgentEmail
											"
										>
											{{ "error.invalidEmail" | translate }}</mat-error
										>
										<mat-error
											>{{ "common.email" | translate }}
											{{
												custFormGroup.controls.AgentEmail.errors
													| inputError
													| translate
											}}</mat-error
										>
									</mat-form-field>

									<section class="mt-sm">
										<mat-checkbox
											(change)="pdpaAgreement = !pdpaAgreement"
											class="checkbox-align-top"
										>
											{{ "common.pdpa" | translate }}

											<a
												[href]="'common.pdpaHref' | translate"
												target="blank"
												style="text-decoration: none"
											>
												<span class="color-primary">
													{{ "common.pdpaLink" | translate }}
												</span>
											</a>
										</mat-checkbox>
									</section>

									<button
										mat-flat-button
										[disabled]="
											!vehicleFormGroup.valid ||
											!custFormGroup.valid ||
											!pdpaAgreement
										"
										*ngIf="
											vehicleFormGroup.controls['BusinessType'].value ===
											businessTypeOption.transferOwnership
										"
										matStepperNext
										type="button"
									>
										{{ "common.proceed" | translate }}
									</button>
									<button
										mat-flat-button
										[disabled]="
											!vehicleFormGroup.valid ||
											!custFormGroup.valid ||
											!pdpaAgreement
										"
										*ngIf="
											vehicleFormGroup.controls['BusinessType'].value ===
											businessTypeOption.renewal
										"
										type="submit"
									>
										{{ "common.proceed" | translate }}
									</button>
								</form>
							</mat-step>
							<!-- End Of Step 2 -->
							<!-- Step 3 -->
							<mat-step
								[stepControl]="moreDetailsFormGroup"
								class="third"
								*ngIf="
									vehicleFormGroup.controls['BusinessType'].value ===
									businessTypeOption.transferOwnership
								"
							>
								<form
									[formGroup]="moreDetailsFormGroup"
									#moreDetailsForm="ngForm"
									(ngSubmit)="submitFormData()"
								>
									<ng-template matStepLabel>
										<span>{{ "dashboard.stepThree" | translate }}</span>
									</ng-template>

									<mat-form-field appearance="outline">
										<mat-label
											>{{ "common.previousNCD" | translate }} %</mat-label
										>
										<mat-select formControlName="PreviousNCD">
											<mat-option [value]="item" *ngFor="let item of ncdList"
												>{{ item * 100 | number : "1.2-2" }}%
											</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="outline" class="upperc-input">
										<mat-label>{{
											"common.prevCarPlate" | translate
										}}</mat-label>
										<input
											matInput
											formControlName="PreviousVehicleNo"
											mask="A{255}"
											[validation]="false"
											autocomplete="off"
										/>
									</mat-form-field>

									<div>
										<span class="label">
											<mat-label
												>{{ "common.sumInsured" | translate }}:
											</mat-label>
										</span>
										<section class="box-selection">
											<mat-radio-group
												class="b2c-radio"
												aria-label="Select an option"
												formControlName="SumInsuredType"
											>
												<mat-radio-button
													[value]="sumInsuredTypeOption.recommended"
													selected
													>{{ "dashboard.recommended" | translate }}
												</mat-radio-button>
												<mat-radio-button [value]="sumInsuredTypeOption.other"
													>{{ "dashboard.otherAmount" | translate }}
												</mat-radio-button>
											</mat-radio-group>
										</section>
									</div>

									<mat-form-field
										appearance="outline"
										*ngIf="
											moreDetailsFormGroup.controls['SumInsuredType'].value ===
											sumInsuredTypeOption.other
										"
									>
										<mat-label
											>{{ "dashboard.amount" | translate }} (RM)</mat-label
										>
										<input
											matInput
											autocomplete="off"
											formControlName="SumInsured"
											mask="separator.2"
											thousandSeparator=","
											autocomplete="off"
											separatorLimit="99999999999999"
										/>
									</mat-form-field>

									<div class="upload-car-grant">
										<div fxLayout="row" fxLayoutAlign="space-between center">
											<input
												type="file"
												accept="image/*, application/pdf"
												name="carGrant"
												id="carGrant"
												(change)="handleFileInput($event)"
												style="display: none"
											/>
											<div class="mdDesc-f bold">
												{{ "dashboard.uploadCarGrant" | translate }}
											</div>
											<div>
												<button mat-stroked-button type="button">
													<label
														for="carGrant"
														style="
															display: flex;
															justify-content: center;
															align-items: center;
														"
													>
														<mat-icon color="primary">add_circle</mat-icon
														>{{ "common.upload" | translate }}
													</label>
												</button>
											</div>
										</div>
										<ng-container *ngIf="carGrant">
											<div
												fxLayout="row"
												fxLayoutAlign="space-between"
												class="pt-sm"
											>
												<div>
													<span class="underlined-blue">
														{{ carGrant.name }}
													</span>
													<button
														mat-icon-button
														(click)="deleteCarGrantFile()"
													>
														<mat-icon>cancel</mat-icon>
													</button>
												</div>
												<div fxLayout="row" fxLayoutAlign="center center">
													<img
														src="/assets/image/tick-icon.png"
														alt=""
														style="width: 20px; margin-right: 10px"
													/>
													<div class="smTitle-f color-dark-green">
														{{ "common.completed" | translate }}
													</div>
												</div>
											</div>
										</ng-container>
									</div>

									<button
										mat-flat-button
										[disabled]="
											!vehicleFormGroup.valid ||
											!custFormGroup.valid ||
											!moreDetailsFormGroup.valid ||
											!pdpaAgreement ||
											!carGrant
										"
									>
										{{ "common.proceed" | translate }}
									</button>
								</form>
							</mat-step>
							<!-- End Of Step 3 -->
						</mat-stepper>

						<div
							*ngIf="stepper.selectedIndex === 1"
							class="accept-payment-graphic"
						>
							<span>{{ "dashboard.weAccept" | translate }} :</span>
							<img src="assets/image/accepted-payment.png" alt="" />
						</div>
					</mat-card>
				</div>
				<div
					fxFlex
					class="copy-writing"
					fxLayout="column"
					fxLayoutAlign="space-between"
				>
					<div class="title">
						{{ "dashboard.desc" | translate }}
					</div>
					
					<div class="desc">
						Powered by
						<img src="/assets/image/ps-logo-white.png" alt="" class="ps-logo" />
					</div>
				</div>
				<div class="menu-space">
					<!-- <img
					(click)="whatsppRedirect()"
					class="whatsapp-btn"
					src="assets/image/whatsapp-icon.png"
					alt=""
					*ngIf="partnerCode !== 'carsome'"
				/> -->
				</div>
			</div>
		</div>
	</div>

	<mat-menu
		#typeTooltip="matMenu"
		[overlapTrigger]="false"
		class="tooltip-popover"
	>
		<span (mouseleave)="typeTrigger.closeMenu()">
			<div class="title">
				{{ "common.TPFT" | translate }}
			</div>
			{{ "common.fireTheftInsDesc" | translate }}
			<br />
			<br />
			<div class="title">
				{{ "common.COM" | translate }}
			</div>
			{{ "common.comprehensiveInsDesc" | translate }}
		</span>
	</mat-menu>
</ng-container>

<ng-template #instantQuote>
	<app-loading-quotation [formData]="quotationFormData"></app-loading-quotation>
</ng-template>
